import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface SliderProps {
	className?: string,
	children?: ReactNode
}

export default ({
	className,
	children
}: SliderProps) => {

	return (
		<Container className={className}>
			{children}
		</Container>
	)
}


const Container = styled.div`
	overflow-x: auto;
`