import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';

interface IntervalEventProps{
  columnStart? : number
  columnSpan? : number
  image? : any
  eventLink? : string

}

const IntervalEvent = ({ columnStart, columnSpan, image, eventLink } : IntervalEventProps) => {
  const defaultImages = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "user advania-01-01" }) {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
              originalImg
            }
          }
        }
      }
    `
  )

  return(
    <StyledEventItem key={columnStart} to={eventLink} columnStart={columnStart} columnSpan={columnSpan}>
      <StyledEventImageWrapper>
        {image != null && image != undefined ? (
          <Img
            className="image"
            fluid={image.childImageSharp.fluid}
          ></Img>
        ) : (
          <Img
            className="image"
            fluid={defaultImages.file.childImageSharp.fluid}
          ></Img>
        )}
      </StyledEventImageWrapper>
      <StyledOverflow></StyledOverflow>
    </StyledEventItem>
  )
}

export default IntervalEvent;

const StyledOverflow = styled.div`
  position:absolute;
  left:0;
  height:50px;
  width:100%;
  background-color:rgba(255,255,255,0.25);
  border-radius: 50px;
`

const StyledEventItem = styled(Link)<IntervalEventProps>`
  height: 100%;
  grid-row: 1 / span 1;
  grid-column: ${({ columnStart }) => columnStart } / span ${({ columnSpan }) => columnSpan };
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position:relative;
`

const StyledEventImageWrapper = styled.div`
  width: 100%;
  height: 50px;
  width: 50px;
  overflow: hidden;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position:absolute;
  left:0;
  z-index:2;

  .image {
    height: 100%;
    width: 100%;
    img {
      object-position: top center !important;
    }
  }
`