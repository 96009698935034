import React, { CSSProperties, ReactNode, useState } from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'
import { CSSTransition } from 'react-transition-group'

const TRANSITION_TIME = 250

interface ChildrenFunctionArgs {
	isHovered: boolean
}

interface CardProps {
	className?: string,
	style?: CSSProperties,
	to: string,
	transitionDelay?: number,
	children: ReactNode | ((args: ChildrenFunctionArgs) => ReactNode)
}
// TODO: Make the component more extendable so he can be a link or just a div.
export default ({
	className,
	style,
	to,
	transitionDelay = 0,
	children
}: CardProps) => {
	const [isHovered, setIsHovered] = useState(false)

	return (
		<CSSTransition
			in
			appear
			exit={false}
			classNames="fade-in"
			unmountOnExit
			timeout={TRANSITION_TIME + transitionDelay}
			style={{ transitionDelay: `${transitionDelay}ms` }}
		>
			<Container
				to={`${to}`}
				className={className}
				style={style}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				{typeof children === "function" ? children({ isHovered }) : children}
			</Container>
		</CSSTransition>
	)
}

const Container = styled(Link)`
	border-radius: 25px;
	background: ${({ theme }) => theme.colors.white};
	text-decoration: none;

	height: 16.6667vw;
	min-width: 26.1111vw;
	width: 26.1111vw;

	/* Given base 10px when is x * 1440 = 10; x = 0.694444vw */
	font-size: calc(0.694444vw);

	transform: translateY(-1vw);

	&.fade-in-appear {
		transition: transform ${TRANSITION_TIME}ms ease-in-out;
	}

	&.fade-in-appear-active {
		transform: translateY(0);
	}

	&.fade-in-appear-done {
		transform: translateY(0);
		transition: transform 0.45s cubic-bezier(0.65, 0, 0.076, 1);

		@media (hover: hover) {
			&:hover {
				transform: translateY(-1.38889vw);
			}
		}
	}
`
export const Spacer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	font-size: 100%;
	padding: 3em; // 10px * x = 30.
	padding-top: 3.5em; // 10px * x = 35.
`

export const ContentTop = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.8em;
`

export const ContentTopText = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
	font-style: normal;
	font-weight: normal;
	text-transform: capitalize;

	font-size: 1.4em;
	line-height: 1.3571em;
`

export const ContentMain = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
	font-style: normal;
	font-weight: bold;
	flex: 1;

	font-size: 1.7em;
	line-height: 124%;
`

export const ContentBottom = styled.div`
	display: flex;
	justify-content: space-between;
`